import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController,ModalController, NavController } from '@ionic/angular';
import { Global } from 'src/global/global';
import { ApiProvider } from 'src/providers/api/api';
import { CustomToast } from 'src/providers/toast/custom-toast';
import { MediaPreviewPage } from '../media-preview/media-preview.page';

@Component({
  selector: 'app-approved-media',
  templateUrl: './approved-media.page.html',
  styleUrls: ['./approved-media.page.scss'],
})
export class ApprovedMediaPage implements OnInit {

  text: string;
   // mediaList : any;
   mediaList: any[] = [];
   paginatedMediaList: any[] = [];
   currentPage: number = 1;
   itemsPerPage: number = 10;

  constructor(
    public globalData : Global,
    public navCtrl: NavController,
    public loadingCtrl : LoadingController, 
    public apiProvider : ApiProvider,
    public toast : CustomToast,
    public router: Router,
    public modalCtrl : ModalController, 
    ) {
      console.log('Hello ApprovedMediaComponent Component');
      this.getMediaToBeApprovedList()
     }

  ngOnInit() {
  }
  async ionViewDidEnter() {
    await this.getMediaToBeApprovedList();
    this.paginateMediaList();
  }


  async doRefresh(event) {
    await this.getMediaToBeApprovedList();
    this.currentPage = 1;
    event.target.complete();
  }


  async getApprovedMediaList1()
  {
    const loader = await this.loadingCtrl.create({
      message: "Please Wait",
    });
    loader.present();

    var url = this.globalData.baseUrl + "Media/GetAllMediaOfApprover";

    var body = "{\"ApproverID\" : " + this.globalData.user.ApproverId + "}";

    this.apiProvider.postAPICalls( url , body )
     .subscribe((response) => {
        loader.dismiss();
        console.log("Response is",response);
        var obj = JSON.parse(response);
        if(obj.Code == 1)
        {
          // debugger
          this.globalData.approvedMediaList = obj.Data;
          console.log(this.globalData.approvedMediaList)
        }
        else
        {
          this.toast.showError(obj.Message);          
        }       
        
    }, (error) => {
      loader.dismiss();
      console.log("Error.error is",error.error);
      this.toast.showError("Error while Login");
        //Error Block
    });
  }

  async getMediaToBeApprovedList() {
    const loader = await this.loadingCtrl.create({
      message: 'Please Wait',
    });
    loader.present();
  
    try {
      const url = this.globalData.baseUrl + 'Media/GetAllMediaOfApprover';
      const body = JSON.stringify({ ApproverID: this.globalData.user.ApproverId });
  
      const response = await this.apiProvider.postAPICalls(url, body).toPromise();
      const obj = JSON.parse(response);
  
      if (obj.Code === 1) {
        this.globalData.mediaList = obj.Data; // Store the fetched data in globalData.mediaList
        this.paginateMediaList(); // Call paginateMediaList() after fetching the data
      } else {
        this.toast.showError(obj.Message);
      }
    } catch (error) {
      console.error(error);
      this.toast.showError('Error while fetching media data.');
    } finally {
      loader.dismiss();
    }
  }
  
  
  paginateMediaList() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedMediaList = this.globalData.mediaList.slice(0, endIndex);
  }

  async loadMoreData(event) {
    const startIndex = this.paginatedMediaList.length; // Get the starting index for the new data
    const endIndex = startIndex + this.itemsPerPage; // Calculate the ending index
  
    // Fetch the new data from the globalData.mediaList based on the indices
    const newData = this.globalData.mediaList.slice(startIndex, endIndex);
  
    // Append the new data to the paginatedMediaList
    this.paginatedMediaList = [...this.paginatedMediaList, ...newData];
  
    event.target.complete();
  
    // Disable infinite scroll when all data is loaded
    if (this.paginatedMediaList.length >= this.globalData.mediaList.length) {
      event.target.disabled = true;
    }
  }






  async previewMediaClicked(pathR)
  {
    // debugger;
    console.log(pathR)
    var path = pathR.ImagePath
    var previewpath= pathR.PreviewImagePath
    if (path.includes("../")) {
        path = path.replace("../", "");
    }

    console.log("Selected Path is ", path)
    var mediaType;
    if (path.includes(".mov") || path.includes(".mp4")) {
      mediaType = 3;
    } else if (path.includes(".mp3") || path.includes(".wav")) {
      mediaType = 2;
    } else if (path.includes(".jpg") || path.includes(".jpeg") || path.includes(".png") || path.includes(".bmp") || path.includes(".gif")) {
      mediaType = 1;
    }
    const modal =  await this.modalCtrl.create({
      component:MediaPreviewPage,
      backdropDismiss:false,
      componentProps: {
        'path':this.globalData.imgDomain + path,
        'previewpath':this.globalData.imgDomain + previewpath,
        'mediaType':mediaType
      }
    });
    return modal.present();
  }
}
